import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Modal,
    Slider,
    Stack,
} from "@mui/material";
import { CATEGORY_COLORS, COLORS, MODEL_PROPTYPES } from "../../../constants";
import { useMemo, useState } from "react";

import { AnnotatedCanvas } from "../../../components/AnnotatedCanvas";
import { BACKEND_ROUTES } from "../../../backendRoutes";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { LightTooltip } from "../../../components/LightTooltip";
import PropTypes from "prop-types";
import { TraitValueTable } from "./TraitValueTable";
import useSWR from "swr";

EntityFileVisualizer.propTypes = {
    entityFile: PropTypes.shape(MODEL_PROPTYPES.EntityFile).isRequired,
    entity: PropTypes.shape(MODEL_PROPTYPES.Entity).isRequired,
    xTrait: PropTypes.string,
    yTrait: PropTypes.string,
};

function initCategoryVisuParams(categories) {
    return categories.map((category) => {
        const categoryColor =
            CATEGORY_COLORS[category.name?.toLowerCase()] ?? "red";
        return {
            ...category,
            checked: true,
            lineWidth: 3,
            color: categoryColor.startsWith("#")
                ? categoryColor
                : COLORS[categoryColor],
        };
    });
}

/**
 * It is assumed that if entityFile.annotation.categories exists, it is an array of objects,
 * each of which contains an id and a name. (Otherwise the page might crash)
 */
export function EntityFileVisualizer({ entityFile, entity, xTrait, yTrait }) {
    const [openModal, setOpenModal] = useState(false);

    const [categoryVisuParams, setCategoryVisuParams] = useState(
        entityFile.annotation?.categories
            ? initCategoryVisuParams(entityFile.annotation.categories)
            : null
    );

    const imageData = useMemo(
        () => ({
            roi: entityFile.roi,
            annotations: entityFile.annotation?.annotations,
            categories: entityFile.annotation?.categories,
        }),
        [entityFile]
    );

    const { data: presignedUrl, error: presignedUrlFetchError } = useSWR(
        `${BACKEND_ROUTES.FILES}/${entityFile.fileUuid}/${BACKEND_ROUTES.FILE_URL}`
    );

    const mergedError = presignedUrlFetchError;

    if (mergedError) return <FetchErrorAlert error={mergedError} />;

    return (
        <>
            <Modal
                sx={{ m: 5, overflow: "scroll" }}
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <Stack direction="column">
                    {categoryVisuParams && (
                        <Box
                            sx={{
                                backgroundColor: "white",
                                pl: 2,
                                borderRadius: 2,
                            }}
                        >
                            <CategoryCheckboxGroup
                                categoryVisuParams={categoryVisuParams}
                                setCategoryVisuParams={setCategoryVisuParams}
                            />
                        </Box>
                    )}
                    <Box>
                        <AnnotatedCanvas
                            imageSrc={presignedUrl}
                            imageData={imageData}
                            categoryVisuParams={categoryVisuParams}
                        />
                    </Box>
                </Stack>
            </Modal>

            <Grid
                container
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid item xs={12}>
                    {categoryVisuParams && (
                        <CategoryCheckboxGroup
                            categoryVisuParams={categoryVisuParams}
                            setCategoryVisuParams={setCategoryVisuParams}
                        />
                    )}
                </Grid>

                <Grid item xs={6}>
                    <Box sx={{ cursor: "pointer" }}>
                        <AnnotatedCanvas
                            imageSrc={presignedUrl}
                            imageData={imageData}
                            onClick={() => setOpenModal(true)}
                            categoryVisuParams={categoryVisuParams}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <TraitValueTable
                        entity={entity}
                        xTrait={xTrait}
                        yTrait={yTrait}
                    />
                </Grid>
            </Grid>
        </>
    );
}

CategoryCheckboxGroup.propTypes = {
    categoryVisuParams: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            checked: PropTypes.bool.isRequired,
            lineWidth: PropTypes.number.isRequired,
            color: PropTypes.string.isRequired,
        })
    ).isRequired,
    setCategoryVisuParams: PropTypes.func.isRequired,
};

function CategoryCheckboxGroup({ categoryVisuParams, setCategoryVisuParams }) {
    const handleChange = (event, source) => {
        setCategoryVisuParams((prevCategoryVisuParams) => {
            const idx = prevCategoryVisuParams.findIndex(
                (category) => category.name === event.target.name
            );

            const newCategoryValue = { ...prevCategoryVisuParams[idx] };

            switch (source) {
                case "checkbox":
                    newCategoryValue.checked = event.target.checked;
                    break;
                case "lineWidth":
                    newCategoryValue.lineWidth = event.target.value;
                    break;
                case "color":
                    newCategoryValue.color = event.target.value;
                    break;
                default:
                // Do nothing
            }

            return prevCategoryVisuParams.toSpliced(idx, 1, newCategoryValue);
        });
    };

    return (
        <FormGroup row>
            {categoryVisuParams.map((category) => (
                <LightTooltip
                    key={category.id}
                    title={
                        <Stack direction="row" spacing={1} alignItems="center">
                            <input
                                type="color"
                                name={category.name}
                                value={category.color}
                                onChange={(event) =>
                                    handleChange(event, "color")
                                }
                            />
                            <Box
                                sx={{
                                    width: 100,
                                    px: 2,
                                    alignItems: "center",
                                    display: "flex",
                                }}
                            >
                                <Slider
                                    size="small"
                                    name={category.name}
                                    min={1}
                                    max={10}
                                    step={1}
                                    value={category.lineWidth}
                                    onChange={(event) =>
                                        handleChange(event, "lineWidth")
                                    }
                                />
                            </Box>
                        </Stack>
                    }
                >
                    <FormControlLabel
                        label={category.name}
                        control={
                            <Checkbox
                                name={category.name}
                                checked={category.checked}
                                onChange={(event) =>
                                    handleChange(event, "checkbox")
                                }
                                sx={{
                                    color: category.color,
                                    "&.Mui-checked": {
                                        color: category.color,
                                    },
                                }}
                            />
                        }
                    />
                </LightTooltip>
            ))}
        </FormGroup>
    );
}
